/**
 * MinersFactory | news (twitter) page
 */

import React from "react"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"

const NewsPage = () => (
    <SubPage>
        <SEO
            title="Aktuelle Neuigkeiten vom Server"
            description="Was gibt's Neues? Aktuelle Ankündigungen der Server-Leitungen, neue Welten und Server-Abteile sowie Infos zum Server allgemein."
        />
        <div className="container">
            <a
                className="twitter-timeline"
                data-lang="de"
                data-dnt="true"
                href="https://twitter.com/MinersFactory?ref_src=twsrc%5Etfw"
            >
                Aktuelle News von MinersFactory auf Twitter
            </a>
        </div>
    </SubPage>
)

export default NewsPage
